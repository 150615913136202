import { BoxProps } from '@mui/material';
import SvgIconStyle from './SvgIconStyle';

interface Props extends BoxProps {
  name: string
}

export default function Logo({ name, sx }: Props) {
  return <SvgIconStyle src={`/assets/logo/${name}.svg`} />;
}
