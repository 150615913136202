import { useEffect } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { NAVBAR } from '../../config';
// components
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import { NavSectionVertical } from '../nav-section';
//
import navConfig from './NavConfig';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import Iconify from 'components/Iconify';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();


  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, []);

  const renderContent = (

    <Stack
      justifyContent="space-between"
      height="100%"
    >
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <Stack>
          <Stack
            spacing={3}
            sx={{
              pt: 3,
              pb: 2,
              px: 2.5,
              flexShrink: 0,
              ...(isCollapse && { alignItems: 'center' }),
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Logo name='logo_bou' />

              {isDesktop && !isCollapse && (
                <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
              )}
            </Stack>

            <NavbarAccount isCollapse={isCollapse} />
          </Stack>

          <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

        </Stack>
      </Scrollbar >
      <Stack
        paddingTop={2}
        paddingBottom={2}
      >
        <Logout
          texto={isCollapse ? "" : "Cerrar sesión"}
        />
      </Stack>
    </Stack>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}

        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

type PropsTwo = {
  texto: String
};
const Logout = (texto: PropsTwo) => {

  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      logout();
      localStorage.clear();
      window.location.href = PATH_AUTH.login;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      onClick={handleLogout}
    >
      <Iconify icon='mdi:logout' width={24} height={24} color='GrayText' />
      <Typography
        variant="body1"
        color="GrayText"
      >
        {texto?.texto}
      </Typography>
    </Button>
  );
};
