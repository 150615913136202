import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from '../@types/auth';
//
import { COGNITO_API } from '../config';
import { interval } from 'rxjs';
import { PATH_AUTH } from 'routes/paths';

// CAUTION: User Cognito is slily difference from firebase, so be sure to read the doc carefully.

export const UserPool = new CognitoUserPool({
  UserPoolId: `${COGNITO_API.userPoolId}`,
  ClientId: `${COGNITO_API.clientId}`,
});

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT',
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timer$ = interval(25000);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else {
              try {
                const attributes = await getUserAttributes(user);
                const token = session?.getAccessToken().getJwtToken();
                const idToken = session?.getIdToken().getJwtToken();
                localStorage.setItem("idToken", idToken!)
                localStorage.setItem("accessToken", token as string);
                // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
                // axios.defaults.headers.common.Authorization = token as string;
                dispatch({
                  type: Types.auth,
                  payload: { isAuthenticated: true, user: attributes },
                });
                resolve({
                  user,
                  session,
                  headers: { Authorization: token },
                });
              } catch (error: any) {
                if (error.code === "NotAuthorizedException") {
                  logout();
                  localStorage.clear();
                  window.location.href = PATH_AUTH.login;
                }
              }
            }
          });
        } else {
          dispatch({
            type: Types.auth,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
    const subs = timer$.subscribe({
      next: () => {
        initial();
      }
    });
    return () => {
      subs.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // same thing here
  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: Types.logout });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user?.name || 'Minimals',
          role: 'admin',
          ...state.user,
        },
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
