import { UserPool } from "contexts/AwsCognitoContext";
import LoadingScreen from "./LoadingScreen";
import { ReactNode, useEffect, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Box } from "@mui/material";
import { PATH_AUTH } from "routes/paths";

type AuthProviderProps = {
    children: ReactNode;
};

function AuthControl({ children }: AuthProviderProps) {

    const [loading, setLoading] = useState(true);

    const loadingSession = () => {
        const isAuthRoute = window.location.pathname.startsWith('/auth');
        if (!localStorage.getItem("accessToken")) {
            if (!isAuthRoute) {
                window.location.href = "/auth";
            }
            setLoading(false);
            return;
        }
        const user = UserPool.getCurrentUser();

        if (user) {
            user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
                try {
                    if (err) {
                        user.signOut();
                        localStorage.clear();
                        window.location.href = PATH_AUTH.login;
                    } else {
                        const token = session?.getAccessToken().getJwtToken();
                        localStorage.setItem("accessToken", token as string);
                        const idToken = session?.getIdToken().getJwtToken();
                        localStorage.setItem("idToken", idToken!)
                        setLoading(false);
                    }
                } catch (error) {
                    console.error("ERROR:/n")
                    console.error(error);
                    // user.signOut();
                    // window.location.href = "/auth";
                }
            });
        }
    }

    useEffect(() => loadingSession(), []);

    return loading ? <LoadingScreen /> : <Box>{children}</Box>;
}

export { AuthControl };